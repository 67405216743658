.navbar-brand img{
    width: 150px;
    
}
.navbar{
    position: fixed;
}
img{
    margin-left: 10px;
    height: 40px;
}