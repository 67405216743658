
p{
    
   
    font-size: 20px;
    
    color:  black;
    
}
.button-container{
    display: flex;
    width: 500px;
    justify-content: space-between;
}
.btn-cargar {
    padding: 10px 20px;
    border-radius: 5px;
    height: 40px;
    border: none;
    background-color: white;
    box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
    cursor: pointer;
}
@media(max-width:507px){
    .button-container{
        width: 300px;
        
    } 
    
}

.chart{
    background-color: white;
}
.legend{
    display: none;
}
.ccampo{
    color:#00E396 ;
}
.pmarchitez{
    color: red;
}
.jej{
    justify-content: center;
    width: 100%;
    height: 400px;
}

.carousel-dark .carousel-indicators {
 display: none;
}
.colors{
    display: flex;
    justify-content: center;
    
}
.baja{
    width: 20px;
    height: 20px;
    background-color: #FFCCCC;
    border-radius: 20%;
}
.media{
    width: 20px;
    height: 20px;
    background-color: #C2E6DA;
    border-radius: 20%;
}
.alta{
    width: 20px;
    height: 20px;
    background-color: #D0E8FA;
    border-radius: 20%;
}
.report{
    display: flex;
    justify-content: center;
}
 .titulo-grafica{
    text-align: left;
    margin-left: 10px;

}

.titulo-general{
    text-align: left;
    margin-left: 10px;

}
.info{
    text-align: left;
    margin-left: 10px;
}
.info-general{
    font-weight: bold;
    margin-left: 10px;
}
.grafica-titulo{
    text-align: center;
    font-weight: bold;
}
.boxplot-text{
    text-align: left;
    font-weight: 500;
    margin-left: 10px;
}
.humedad{
    font-weight:500 ;
}
.tramp{
    height: 8px;
}