*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Chillax-Variable;
  
}
.App{
 width: 100%;
 background-color: #f3f0f0;
}
.container-fluid{
  padding-left:0;
 padding-right:0;
}

.w3-container{
  /* padding: 5px; */
  overflow: hidden;
  }