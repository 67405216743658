.tr td{
    font-weight: 500;
}
td{
    font-size: 20px;
}
.jeje{
    height: 15px;
}
th{
    font-size: 20px;
}

/* roof */