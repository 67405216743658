.btn-cargar {
    padding: 10px 20px;
    margin-top: 50px;
    border-radius: 5px;
    border: none;
    box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
    cursor: pointer;
    color: black;
    width: 106px;
    height: 44px;
    align-self: center;
    justify-self: center;  
}

.button-container{
    display: flex;
    justify-content: center;
}
.nombre{
    margin-top: 0;
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 0;
}
.form-data{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 65vh;
    margin: 0 auto;
    width: 100%;
    /* background-image: url('../images/fondo2.0.png'); */
    background-image: linear-gradient( to bottom, rgba(15, 15, 15, 0.5), rgba(11, 11, 11, 0.5) ), url("../images/fondo2.0.png");
    background-size: cover;
    border-bottom: 1px solid gray;
    overflow: hidden;
    

}
.text-form{
    margin-bottom: 50px;
    font-size: 25px;
    text-align: center;
    color:  white;
    font-weight: bold;
}
.btn-aceptar{
    padding: 10px 20px;
    margin-top: 50px;
    border-radius: 5px;
    border: none;
    box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
    cursor: pointer;
    color: black;
    width: 106px;
    align-self: center;
    justify-self: center; 
    
}
label{
    display: inline;
    text-align: center;
    font-weight: bold;
    color:white ;
}
.btn-aceptar a{
    text-decoration: none;
    color: black;
    
}
.btn-cargar a{
    text-decoration: none;
    color: black;
    
}
.label-sensor{
    color: white;
}
