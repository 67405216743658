.about-text{
    text-align: start;
    font-weight: 400;
}
h4{
    text-align: center;
}
.sensor{
    width: 100%;
    height: 100%;
    justify-self: center;

    overflow: hidden;    
}
.img-container{
    display: flex;
    justify-content: center;

}
